import { Card, Checkbox, Col, Input, Row } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import IncDecCounter from "../../../../../../../../components/Layout/IncDecCounter";
import { getFormattedDecimalNumber } from "../../../../../../../../utils/digitHelper";
import {getCapRateOrDefault, getDiscountRateOrDefault} from "../../../../../../../../utils/dcfParamsHelper";
import {AssetDto} from "reia-rest-client";

function VeCapitalizeRate() {
  const dispatch = useDispatch();
  const [bpDiscountRate, setBpDiscountRate] = useState(50);
  const [spreadEC, setSpreadEC] = useState(50);
  const [lockExitCheck, setLockExitCheck] = useState(false);
  const calculation = useSelector((state) => state.calculation);
  const { asset }: { asset: AssetDto } = useSelector((state) => state.assets);
  const { assetTypesCapRatesDefaults } = useSelector((state) => state.settings);
  const { assetDCFResult } = calculation;
  const { discountRate, exitCapRate } = calculation.assetDCFParams;

  const assetValue = assetDCFResult?.dcfResult?.assetCashFlow?.netAssetValue;

  const setDiscountRate = (newValue) => {
    dispatch({
      type: "calculation/setAssetDCFParams",
      payload: { discountRate: newValue },
    });
    if (spreadEC < 0 || spreadEC > 0 && lockExitCheck && newValue) {
      dispatch({
        type: "calculation/setAssetDCFParams",
        payload: { exitCapRate: newValue + spreadEC / 100 },
      });
    }
  };

  const setExitCapRate = (newValue) => {
    dispatch({
      type: "calculation/setAssetDCFParams",
      payload: { exitCapRate: newValue },
    });
    // if (spreadEC < 0 || spreadEC > 0) {
    //   dispatch({
    //     type: "calculation/setAssetDCFParams",
    //     payload: { discountRate: newValue - spreadEC / 100 },
    //   });
    // }
  };

  return (
    <Card bordered={false} className="assets_card">
      <div className="d-flex justify-content-between align-items-center">
        <h3 className="text-black">Capitalization</h3>
        <Checkbox checked={lockExitCheck} onChange={(e) => setLockExitCheck(e.target.checked)}><span style={{ fontSize: '12px' }}>Lock Exit</span></Checkbox>
      </div>

      <Row className="pt-2 align-items-center" gutter={12}>
        <Col xs={24} lg={12} xl={6} >
          <h4 className="holding_text">Discount rate %</h4>
          <IncDecCounter
            incDecStep={bpDiscountRate / 100}
            placeholder={getFormattedDecimalNumber(getDiscountRateOrDefault(calculation.assetDCFParams, assetTypesCapRatesDefaults, calculation.calculationDetail.assetType))}
            value={discountRate ? getFormattedDecimalNumber(discountRate) : null}
            // label={"%"}
            setIncDecValue={setDiscountRate}
          />
        </Col>
        <Col xs={24} lg={12} xl={6}>
          <h4 className="holding_text">Exit cap rate %</h4>
          <div className="d-flex align-items-center">
            <IncDecCounter
              incDecStep={bpDiscountRate / 100}
              placeholder={getFormattedDecimalNumber(getCapRateOrDefault(calculation.assetDCFParams, assetTypesCapRatesDefaults, calculation.calculationDetail.assetType))}
              value={exitCapRate ? getFormattedDecimalNumber(exitCapRate) : null}
              disabled={lockExitCheck}
              // label={"%"}
              setIncDecValue={setExitCapRate}
            />
          </div>
        </Col>
        <Col xs={24} lg={12} xl={6} >
          <div className="d-flex align-items-center" style={{ marginBottom: "0.5rem", gap: '0px' }}>
            <h4 className="holding_text mb-0">Spread BP</h4>
          </div>
          <div className="d-flex align-items-center">
            {/* justify-content-center */}
            {/* <div> */}
            {/* <IncDecCounter
                incDecStep={bpDiscountRate / 100}
                value={discountRate}
                setIncDecValue={setDiscountRate}
              /> */}
            <Input
              style={{
                border: "1px solid #B7B7B7",
                padding: "12px",
              }}
              type="number"
              value={lockExitCheck ? spreadEC : null}
              disabled={!lockExitCheck}
              placeholder={spreadEC}
              onChange={e => { setSpreadEC(e.target.value) }}
            />
            {/* </div> */}

          </div>
        </Col>
        <Col xs={24} lg={12} xl={6}>
          <h4 className="holding_text">Steps BP</h4>
          <div className="d-flex align-items-center ">
            {/* justify-content-center */}
            <div>
              <Input
                style={{
                  border: "1px solid #B7B7B7",
                  padding: "12px",
                }}
                type="number"
                value={bpDiscountRate}
                onChange={(e) => {
                  setBpDiscountRate(e.target.value);

                }}
              />
            </div>

          </div>
        </Col>
      </Row>
    </Card>
  );
}
export default VeCapitalizeRate;
