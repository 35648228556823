//--------------------------- ASSET DCF PARAMS DEFAULTS ---------------------------//

export const DCF_PARAM_INFLATION = 2
export const DCF_PARAM_ANALYSE_DURATION_IN_MONTHS = 120
export const DCF_PARAM_RENT_TYPE_I18N_KEY = "core-data.baseRentTypes.potential-rent"
export const DCF_PARAM_RENT_TYPE_I18N_LABEL = "Potential Rent"
export const DCF_PARAM_TERMINAL_TYPE_I18N_KEY= "core-data.terminalValueTypes.net"
export const DCF_PARAM_TERMINAL_TYPE_I18N_LABEL= "Net"
export const DCF_PARAM_DISCOUNT_RATE = 5
export const DCF_PARAM_EXIT_CAP_RATE = 6

//--------------------------- RENT ROLL DEFAULTS ---------------------------//
export const DCF_RENT_ROLL_VACANT_TENANT_NAME = "Vacant"
export const DCF_RENT_ROLL_LET_TENANT_NAME = "n.a."
export const DCF_RENT_ROLL_INITIAL_RENEWAL_PROB = 0
export const DCF_RENT_ROLL_CONTINUING_RENEWAL_PROB = 0
export const DCF_RENT_ROLL_LEASE_DURATION_OPEN_ENDED = 60
export const DCF_RENT_ROLL_LEASE_DURATION_NEW_LEASES = 60
export const DCF_RENT_ROLL_INDEXATION_TYPE_I18N_KEY = "core-data.indexationTypes.none"
export const DCF_RENT_ROLL_NRC_TERM_MAINT = 8
export const DCF_RENT_ROLL_NRC_TERM_MGMT = 2
export const DCF_RENT_ROLL_NRC_TERM_OTHER = 0.5
export const DCF_RENT_ROLL_NRC_RELET_MAINT = 8
export const DCF_RENT_ROLL_NRC_RELET_MGMT = 2
export const DCF_RENT_ROLL_NRC_RELET_OTHER = 0.5
export const DCF_RENT_ROLL_VOID_PERIOD_INITIAL = 12
export const DCF_RENT_ROLL_VOID_PERIOD_CONTINUING = 12
export const DCF_RENT_ROLL_VACANCY_COSTS = 36
export const DCF_RENT_ROLL_TENANT_IMPROVEMENT_INITIAL = 250
export const DCF_RENT_ROLL_TENANT_IMPROVEMENT_CONTINUING = 250
export const DCF_RENT_ROLL_AGENT_COSTS = 3
export const DCF_RENT_ROLL_INDEXATION_CYCLE = 12

//--------------------------- RENT ROLL HVL DEFAULTS ---------------------------//

export const DCF_RENT_ROLL_HVL_AREA_UNIT = 5000
export const DCF_RENT_ROLL_HVL_OCCUPIED_AREA = 100
export const DCF_RENT_ROLL_HVL_CURRENT_RENT_SQM = 20
export const DCF_RENT_ROLL_HVL_MARKET_RENT_SQM = 30
export const DCF_RENT_ROLL_HVL_MAINT_TERM = 0
export const DCF_RENT_ROLL_HVL_MGMT_TERM = 0
export const DCF_RENT_ROLL_HVL_OTHER_TERM = 10
export const DCF_RENT_ROLL_HVL_MAINT_RELET = 0
export const DCF_RENT_ROLL_HVL_MGMT_RELET = 0
export const DCF_RENT_ROLL_HVL_OTHER_RELET = 0


//--------------------------- FINANCING DEFAULTS ---------------------------//
export const DCF_FINANCING_TYPE_I18N_KEY = "core-data.financingTypes.bullet-loan"
export const DCF_FINANCING_TYPE_I18N_LABEL = "Bullet Loan"
export const DCF_FINANCING_LTV = "0.5"
export const DCF_FINANCING_INTEREST_RATE = "1.5"
export const DCF_FINANCING_SWAP_RATE = "2.0"
export const DCF_FINANCING_AMORTIZATION = "0"
export const DCF_FINANCING_TARGET_VALUE = "0"
export const DCF_FINANCING_PREPAYMENT_FEE = "0"

