import { Button, Card, Checkbox, Col, Input, Row, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getByTypeDropdown } from "../../../../../../../../app/features/Lookups/lookup.slice";
import { defaultNotifyToaster } from "../../../../../../../../utils/helpers";
import { I18n } from "reia-rest-client";
import IncDecCounter from "../../../../../../../../components/Layout/IncDecCounter";
import { getFormattedEnglishNumber } from "../../../../../../../../utils/digitHelper";
import {
    getBaseRentTypeOrDefaultLabel,
    getDurationInMonthsOrDefault, getTerminalRentTypeOrDefaultLabel
} from "../../../../../../../../utils/dcfParamsHelper";

function VeAssetBaseRentType(props) {
    const dispatch = useDispatch();
    const assetDCFParams = useSelector((state) => state.calculation.assetDCFParams);
    const {
        durationInMonths,
        baseRentType,
        terminalValueType
    } = assetDCFParams;
    const { baseRentTypes, terminalValueTypes } = useSelector((state) => state.lookUps);

    useEffect(() => {
        const finalData = {
            catalog: "core-data",
            context: "baseRentTypes",
        };
        dispatch(getByTypeDropdown({ finalData, defaultNotifyToaster }));
    }, [dispatch]);

    useEffect(() => {
        const finalData = {
            catalog: "core-data",
            context: "terminalValueTypes",
        };
        dispatch(getByTypeDropdown({ finalData, defaultNotifyToaster }));
    }, [dispatch]);

    const baseRentTypesMap = {};
    baseRentTypes?.forEach((item: I18n) => (baseRentTypesMap[item.key] = item))

    //will update individual rent from BE
    const IndividualRent = [{ key: 'core-data.terminalValueTypes.individual', translations: { en: "Individual Rent" } }]
    const terminalValueTypesOptions = terminalValueTypes?.concat(IndividualRent)

    const terminalValuesTypesMap = {};
    // terminalValueTypes?.forEach((item: I18n) => (terminalValuesTypesMap[item.key] = item))
    terminalValueTypesOptions?.forEach((item: I18n) => (terminalValuesTypesMap[item.key] = item))

    const setAssetDCFParams = (params) => {
        dispatch({ type: "calculation/setAssetDCFParams", payload: params })
    }
    return (
        <Card bordered={false} className="assets_card">
            <div>
                <h3 className="text-black">Holding Period & Exit Calculation</h3>
            </div>


            <Row className="pt-2" gutter={12}>
                <Col sx={24} md={12} xl={6} >
                    <h4 className="holding_text">Holding Period Yrs</h4>
                    <IncDecCounter min={1} max={30} incDecStep={1} value={durationInMonths / 12}
                        //  label={"Years"}
                        placeholder={getDurationInMonthsOrDefault(assetDCFParams)/12}
                        setIncDecValue={(newValue) => setAssetDCFParams({ durationInMonths: newValue ? newValue * 12 : null})}
                        className="w-100" />
                </Col>
                <Col sx={24} md={12} xl={6}>
                    <h4 className="holding_text">Base Rent</h4>
                    <Select
                        placeholder={getBaseRentTypeOrDefaultLabel(assetDCFParams)}
                        allowClear
                        value={
                            baseRentType
                                ? baseRentType?.key
                                : null
                        }
                        className="w-100"
                        onChange={(e) => setAssetDCFParams({ baseRentType: baseRentTypesMap[e] })}
                    >
                        {baseRentTypes?.map((item: I18n) => (
                            <Select.Option key={item.key} value={item.key}>
                                {item.translations.en}
                            </Select.Option>
                        ))}
                    </Select>
                </Col>

                <Col sx={24} md={12} xl={6}>
                    <h4 className="holding_text"> Terminal Type</h4>
                    <Select
                        placeholder={getTerminalRentTypeOrDefaultLabel(assetDCFParams)}
                        allowClear
                        // value={financingRateObj?.financingTypeId}
                        value={
                            terminalValueType
                                ? terminalValueType?.key
                                : null
                        }
                        className="w-100"
                        onChange={(e) => setAssetDCFParams({ terminalValueType: terminalValuesTypesMap[e] })}
                    >
                        {terminalValueTypesOptions?.map((item: I18n) => (
                            <Select.Option key={item.key} value={item.key}>
                                {item.translations.en}
                            </Select.Option>
                        ))}
                    </Select>
                </Col>
                <Col sx={24} md={12} xl={6}>
                    <h4 className="holding_text">Individual Rent EUR</h4>
                    <div className="d-flex align-items-center ">
                        {/* justify-content-center */}
                        <div>
                            <Input
                                disabled={terminalValueType?.key !== "core-data.terminalValueTypes.individual"}
                                style={{

                                    border: "1px solid #B7B7B7",
                                    padding: "12px",
                                }}
                                placeholder={getFormattedEnglishNumber(125000000)}
                                value={terminalValueType?.key === "core-data.terminalValueTypes.individual" ? getFormattedEnglishNumber(125000000) : null}
                            />
                        </div>

                    </div>
                </Col>
                {/*<Col sx={24} md={8}>*/}
                {/*    <Button*/}
                {/*        type="primary"*/}
                {/*        className="btn-primary border-0"*/}
                {/*        onClick={handleFinancingValue}*/}
                {/*    >*/}
                {/*        Submit*/}
                {/*    </Button>*/}
                {/*</Col>*/}
            </Row>
        </Card>
    )

}

export default VeAssetBaseRentType;