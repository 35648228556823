import {RentRollDto} from "reia-rest-client";
import moment, {Moment} from "moment";

import {DCFOutput} from "reia-dcf-client";
import {DCFParameters} from "reia-rest-client";
import {
    getAccCostsTerminalValue,
    getFinancingAmortization, getFinancingEquity, getFinancingInterestRate, getFinancingLoan,
    getFinancingLTV, getStartingDate,
    getTerminalRentTypeFromI18nKey
} from "./dcfHelper";
import {CalculationDetailDto} from "reia-rest-client";
import {TenantCashFlow} from "reia-dcf-client";
import {AssetKPIsDto} from "reia-rest-client";
import {AssetDto} from "reia-rest-client";
import {mean, sum} from "mathjs";
import {DCF_FINANCING_TYPE_I18N_KEY, DCF_FINANCING_TYPE_I18N_LABEL} from "./dcfDefaults";
import {convertRate, xirr} from "node-irr";
import {XirrInput} from "node-irr";
import {
    getDefaultAccCostsValuesUI,
    getDiscountRateOrDefault,
    getDurationInMonthsOrDefault,
    getRentRollLeaseDurationEnd, getRentRollMarketRentMonthly, getRentRollNonRecCostTermMaint, getRentRollTenantName
} from "./dcfParamsHelper";
import {useEffect} from "react";

//--------------------------------------------- Asset Area Income KPIs -----------------------------------------------//
//--------------------------------------------------------------------------------------------------------------------//
interface AreaAndIncomeDetail {
    totalAreaUnits: number,
    let: number,
    vacant: number,
    vacancy: number,
    occupancy: number,
    grossRentalIncomeSqm: number,
    grossRentalIncomeYear: number,
    nonRecoverableCostsPercentage: number,
    nonRecoverableCostsYear: number,
    netOperatingIncomeSqm: number,
    netOperatingIncomeYear: number,
    marketRentalIncomeSqm: number,
    marketRentalIncomeYear: number,
    marketRentalIncomeLetSqm: number,
    marketRentalIncomeLetYear: number,
    walt: number,
    rentalLevel: number
}
interface AreaAndIncomeDetailByRentalType extends AreaAndIncomeDetail{
    rentalType: string
}
interface AreaAndIncomeDetailByUseType extends AreaAndIncomeDetailByRentalType{
    useTypeName: string,
    useType: string
}
interface AreaAndIncomeDetails {
    totalValues: AreaAndIncomeDetail,
    totalRentalTypeValues:  { [key: string]: AreaAndIncomeDetailByRentalType; },
    useTypeValues: { [key: string]: AreaAndIncomeDetailByUseType; }
}

function getAreaIncomeDetailsZeroValue() : AreaAndIncomeDetails {
    return {
        totalAreaUnits: 0,
        let: 0,
        vacant: 0,
        vacancy: 0,
        occupancy: 0,
        grossRentalIncomeSqm: 0,
        grossRentalIncomeYear: 0,
        nonRecoverableCostsPercentage: 0,
        nonRecoverableCostsYear: 0,
        netOperatingIncomeSqm: 0,
        netOperatingIncomeYear: 0,
        marketRentalIncomeSqm: 0,
        marketRentalIncomeYear: 0,
        marketRentalIncomeLetSqm: 0,
        marketRentalIncomeLetYear: 0,
        walt: 0,
        rentalLevel: 0
    };
}
function addAreaIncomeSummableValues(rentRollDto: RentRollDto, areaAndIncomeDetailValues : AreaAndIncomeDetail, useTypeName : string, useTypesCostsDefaults: {}) {

    areaAndIncomeDetailValues.totalAreaUnits += rentRollDto.rentalSpace
    areaAndIncomeDetailValues.grossRentalIncomeYear += rentRollDto.currentRentPerYear

    const marketRentPerYear = getRentRollMarketRentMonthly(rentRollDto) * 12;
    
    areaAndIncomeDetailValues.marketRentalIncomeYear += marketRentPerYear
    
    const nrctMaintenance = getRentRollNonRecCostTermMaint(rentRollDto, useTypeName, useTypesCostsDefaults)
    const nrctManagement = getRentRollNonRecCostTermMaint(rentRollDto, useTypeName, useTypesCostsDefaults)
    const nrctOtherCosts = getRentRollNonRecCostTermMaint(rentRollDto, useTypeName, useTypesCostsDefaults)

    const nonRecoverableCosts = rentRollDto.rentalSpace * nrctMaintenance/100
        + marketRentPerYear * nrctManagement/100
        + marketRentPerYear * nrctOtherCosts/100

    areaAndIncomeDetailValues.nonRecoverableCostsYear += nonRecoverableCosts

    if(rentRollDto.rentRollStatusType?.key === "core-data.rentRollStatusTypes.let"){

        areaAndIncomeDetailValues.let += rentRollDto.rentalSpace

        areaAndIncomeDetailValues.marketRentalIncomeLetYear += marketRentPerYear
    }
}

function addAreaIncomeDetailsSummableValues(rentRollDto: RentRollDto, useTypeValues: AreaAndIncomeDetailByUseType, rentalTypeValues : AreaAndIncomeDetailByRentalType, totalValues : AreaAndIncomeDetail, useTypeName : string ,useTypesCostsDefaults : {}) {
    addAreaIncomeSummableValues(rentRollDto,useTypeValues, useTypeName, useTypesCostsDefaults)
    addAreaIncomeSummableValues(rentRollDto,rentalTypeValues, useTypeName, useTypesCostsDefaults)
    addAreaIncomeSummableValues(rentRollDto,totalValues, useTypeName, useTypesCostsDefaults)
}

function addAreaIncomeRelativeValues(areaAndIncomeValues : AreaAndIncomeDetail) {

    areaAndIncomeValues.vacant = areaAndIncomeValues.totalAreaUnits-areaAndIncomeValues.let
    areaAndIncomeValues.vacancy = areaAndIncomeValues.vacant/areaAndIncomeValues.totalAreaUnits
    areaAndIncomeValues.occupancy = 1-areaAndIncomeValues.vacancy;
    areaAndIncomeValues.grossRentalIncomeSqm = areaAndIncomeValues.grossRentalIncomeYear/12/areaAndIncomeValues.let
    areaAndIncomeValues.nonRecoverableCostsPercentage = areaAndIncomeValues.nonRecoverableCostsYear/areaAndIncomeValues.marketRentalIncomeYear 
    areaAndIncomeValues.netOperatingIncomeYear = areaAndIncomeValues.grossRentalIncomeYear-areaAndIncomeValues.nonRecoverableCostsYear
    areaAndIncomeValues.netOperatingIncomeSqm = areaAndIncomeValues.netOperatingIncomeYear/12/areaAndIncomeValues.let
    areaAndIncomeValues.marketRentalIncomeSqm = areaAndIncomeValues.marketRentalIncomeYear/12/areaAndIncomeValues.totalAreaUnits
    areaAndIncomeValues.marketRentalIncomeLetSqm = areaAndIncomeValues.marketRentalIncomeLetYear/12/areaAndIncomeValues.let
    
    //Rental Level: grossRentalSqm / marketRentalIncomeLetSqm / -1
    areaAndIncomeValues.rentalLevel = (areaAndIncomeValues.grossRentalIncomeSqm/areaAndIncomeValues.marketRentalIncomeLetSqm)-1
    
}

function addAreaIncomeWeightedValues(rentRollDto: RentRollDto, useTypeValues: AreaAndIncomeDetailByUseType, rentalTypeValues : AreaAndIncomeDetailByRentalType, totalValues : AreaAndIncomeDetail, analysisDate : Moment) {
    
    //WALT: Mietdauer ab AnalysisDate * currentRentGewichtet
    let remainingTerm: number = 0
    
    if(rentRollDto.leaseEndDate){
        const leaseEndDate: Moment = moment(rentRollDto.leaseEndDate);
        
        if(rentRollDto.rentRollStatusType?.key === "core-data.rentRollStatusTypes.let" && leaseEndDate.isSameOrAfter(analysisDate) ){
            remainingTerm = leaseEndDate.diff(analysisDate,"days")/365.25
        }    
    }
    
    const currentRentWeight = rentRollDto.currentRentPerYear/useTypeValues.grossRentalIncomeYear
    const walt = remainingTerm * currentRentWeight

    useTypeValues.walt += walt
    rentalTypeValues.walt += walt * useTypeValues.grossRentalIncomeYear/rentalTypeValues.grossRentalIncomeYear
    totalValues.walt += walt *  useTypeValues.grossRentalIncomeYear/totalValues.grossRentalIncomeYear
    
}

export const getAreaAndIncomeDetails = (rentRolls : RentRollDto[], analysisDate : Moment, useTypesCostsDefaults: {}) : AreaAndIncomeDetails => {
    const areaAndIncomeDetails : AreaAndIncomeDetails = {
        totalValues: getAreaIncomeDetailsZeroValue(),
        totalRentalTypeValues: {},
        useTypeValues: {},
    }
    
    rentRolls.forEach(rentRollDto => {
        const useType = rentRollDto?.useType?.key ? rentRollDto?.useType?.key : "Unknown" 
        const useTypeName = rentRollDto?.useType?.translations?.en ? rentRollDto?.useType?.translations?.en : "Unnamed"
        const rentalType =  rentRollDto?.useType?.values?.unitType ? rentRollDto?.useType?.values?.unitType : "Unknown"
        
        if(!areaAndIncomeDetails.useTypeValues.hasOwnProperty(useType)){
            areaAndIncomeDetails.useTypeValues[useType] = getAreaIncomeDetailsZeroValue();
            areaAndIncomeDetails.useTypeValues[useType].rentalType = rentalType
            areaAndIncomeDetails.useTypeValues[useType].useTypeName = useTypeName
            areaAndIncomeDetails.useTypeValues[useType].useType = useType
        }
        
        if(!areaAndIncomeDetails.totalRentalTypeValues.hasOwnProperty(rentalType)){
            areaAndIncomeDetails.totalRentalTypeValues[rentalType] = getAreaIncomeDetailsZeroValue();
            areaAndIncomeDetails.totalRentalTypeValues[rentalType].rentalType = rentalType
        }

        const useTypeValues: AreaAndIncomeDetailByUseType = areaAndIncomeDetails.useTypeValues[useType] ;
        const rentalTypeValues: AreaAndIncomeDetailByRentalType = areaAndIncomeDetails.totalRentalTypeValues[rentalType];

            addAreaIncomeDetailsSummableValues(rentRollDto, useTypeValues, rentalTypeValues, areaAndIncomeDetails.totalValues, useTypeName, useTypesCostsDefaults);
    })
    
    addAreaIncomeRelativeValues(areaAndIncomeDetails.totalValues);
    
    Object.values(areaAndIncomeDetails.totalRentalTypeValues).forEach(rentalTypeValues => addAreaIncomeRelativeValues(rentalTypeValues))
    Object.values(areaAndIncomeDetails.useTypeValues).forEach(useTypeValues => addAreaIncomeRelativeValues(useTypeValues))

    rentRolls.forEach(rentRollDto => {
        const useType = rentRollDto?.useType?.key ? rentRollDto?.useType?.key : "Unknown"
        const rentalType =  rentRollDto?.useType?.values?.unitType ? rentRollDto?.useType?.values?.unitType : "Unknown"
        const useTypeValues: AreaAndIncomeDetailByUseType = areaAndIncomeDetails.useTypeValues[useType]
        const rentalTypeValues: AreaAndIncomeDetailByRentalType = areaAndIncomeDetails.totalRentalTypeValues[rentalType]

        addAreaIncomeWeightedValues(rentRollDto, useTypeValues, rentalTypeValues, areaAndIncomeDetails.totalValues, analysisDate);
    })
    
    return areaAndIncomeDetails;
}


//--------------------------------------------- Asset KPIs -----------------------------------------------------------//
//--------------------------------------------------------------------------------------------------------------------//

function addAssetKpiSummableValues(rentRollDto: RentRollDto, assetKPIs: AssetKPIsDto) {

    const summableRentRollKeyParam = {
        totalArea: "rentalSpace"
    }

    Object.entries(summableRentRollKeyParam).forEach( entry => {
        assetKPIs[entry[0]] += rentRollDto[entry[1]]
    })

    if(rentRollDto.rentRollStatusType?.key === "core-data.rentRollStatusTypes.let"){
        assetKPIs.let += rentRollDto.rentalSpace
        assetKPIs.marketRentalIncomeLetYear += getRentRollMarketRentMonthly(rentRollDto)*12
    }else{
        assetKPIs.vacant += rentRollDto.rentalSpace
    }
}

function addAssetKpiWeightedValues(rentRollDto: RentRollDto, assetKPIs: AssetKPIsDto, analysisDate : Moment, dcfResult: DCFOutput) {
    
    //WALT: Mietdauer ab AnalysisDate * currentRentGewichtet
    let remainingTerm: number = 0

    if(rentRollDto.leaseEndDate){
        const leaseEndDate: Moment = moment(rentRollDto.leaseEndDate);
        if(rentRollDto.rentRollStatusType?.key === "core-data.rentRollStatusTypes.let" && leaseEndDate.isSameOrAfter(analysisDate) ){
            remainingTerm = leaseEndDate.diff(analysisDate,"days")/365.25
        }
    }

    const tenantCashFlow: TenantCashFlow = dcfResult.tenantCashFlows.find(cashFlow => ""+rentRollDto.id === cashFlow.rentRollId)
    if(tenantCashFlow)
    {
        const currentRentWeight = tenantCashFlow.grossRentalIncome[0]/dcfResult.assetCashFlow.totalGrossRentalIncome[0]
        const walt = remainingTerm * currentRentWeight
        if(!isNaN(walt))
        assetKPIs.walt += walt    
    }
}

function calcYieldValues (assetKpis:AssetKPIsDto, dcfResult:DCFOutput) {
    
    if(dcfResult?.assetCashFlow) {
        const assetCashFlow = dcfResult.assetCashFlow
        
            assetKpis.netInitialYieldCurrentRent= (assetCashFlow.totalNetOperatingIncome[0] * 12) / assetCashFlow.grossAssetValue
            assetKpis.grossInitialYieldCurrentRent= (assetCashFlow.totalGrossRentalIncome[0] * 12) / assetCashFlow.netAssetValue

            assetKpis.netInitialYieldPotentialRent= ((assetCashFlow.totalPotentialRent[0] -
                (assetCashFlow.totalNonRecs[0] + assetCashFlow.otherIncomeCosts_beforeNOI[0])) * 12) / assetCashFlow.grossAssetValue
            assetKpis.grossInitialYieldPotentialRent= (assetCashFlow.totalPotentialRent[0] * 12) / assetCashFlow.netAssetValue

            assetKpis.netInitialYieldMarketRent= ((assetCashFlow.totalMArketRent[0] -
                (assetCashFlow.totalNonRecs[0] + assetCashFlow.otherIncomeCosts_beforeNOI[0])) * 12) / assetCashFlow.grossAssetValue
            assetKpis.grossInitialYieldMarketRent= (assetCashFlow.totalMArketRent[0] * 12) / assetCashFlow.netAssetValue

            assetKpis.netInitialYieldTerminalCurrentRent= (assetCashFlow.totalNetOperatingIncome?.at(-1) * 12) / assetKpis.grossTerminalAssetValue
            assetKpis.grossInitialYieldTerminalCurrentRent= (assetCashFlow.totalGrossRentalIncome?.at(-1) * 12) / assetKpis.netTerminalAssetValue

            assetKpis.netInitialYieldTerminalPotentialRent= ((assetCashFlow.totalPotentialRent?.at(-1) -
                (assetCashFlow.totalNonRecs?.at(-1) + assetCashFlow.otherIncomeCosts_beforeNOI?.at(-1))) * 12) / assetKpis.grossTerminalAssetValue
            assetKpis.grossInitialYieldTerminalPotentialRent= (assetCashFlow.totalPotentialRent?.at(-1) * 12) / assetKpis.netTerminalAssetValue

            assetKpis.netInitialYieldTerminalMarketRent= ((assetCashFlow.totalMArketRent?.at(-1) -
                (assetCashFlow.totalNonRecs?.at(-1) + assetCashFlow.otherIncomeCosts_beforeNOI?.at(-1))) * 12) / assetKpis.grossTerminalAssetValue
            assetKpis.grossInitialYieldTerminalMarketRent= (assetCashFlow.totalMArketRent?.at(-1) * 12) / assetKpis.netTerminalAssetValue

            assetKpis.multipleInitialYieldCurrentRent = 1/assetKpis.grossInitialYieldCurrentRent
            assetKpis.multipleInitialYieldMarketRent = 1/assetKpis.grossInitialYieldMarketRent
            assetKpis.multipleInitialYieldPotentialRent = 1/assetKpis.grossInitialYieldPotentialRent
            
            assetKpis.multipleInitialYieldTerminalCurrentRent = 1/assetKpis.grossInitialYieldTerminalCurrentRent
            assetKpis.multipleInitialYieldTerminalMarketRent =1/assetKpis.grossInitialYieldTerminalMarketRent
            assetKpis.multipleInitialYieldTerminalPotentialRent =1/assetKpis.grossInitialYieldTerminalPotentialRent
    }
}

export const calculateAssetKpis = (calculationDetail : CalculationDetailDto, dcfResult: DCFOutput, assetDCFParams: DCFParameters, assetTypesCapRatesDefaults: {}, landTaxes: {}, acquisitionCostsDefaults: {}) :AssetKPIsDto => {
    
    const analysisDate : Moment = calculationDetail.analysisDate ? moment(calculationDetail.analysisDate) : null
    const rentRolls : RentRollDto[] = calculationDetail.rentRolls ? Object.values(calculationDetail.rentRolls) : null
    
    if(dcfResult?.assetCashFlow && analysisDate && rentRolls)
    {
        const financingDetails = getFinanceDetails(calculationDetail.analysisDate, dcfResult, assetDCFParams, assetTypesCapRatesDefaults, calculationDetail.assetType)

        const accCostsAtSale = getDefaultAccCostsValuesUI(landTaxes, acquisitionCostsDefaults, calculationDetail.federalState, dcfResult?.assetCashFlow?.netAssetValue, assetDCFParams, false)
        const accCostsAtExit = getDefaultAccCostsValuesUI(landTaxes, acquisitionCostsDefaults, calculationDetail.federalState, dcfResult?.assetCashFlow?.netAssetValue, assetDCFParams, true)
        
        const {
            grossValue: grossTerminalAssetValue , netValue: netTerminalAssetValue , landTransferExitValue, notaryCostsExitValue, agentCostsExitValue, totalAcquisitionCostsExitValue
            } = getAccCostsTerminalValue(assetDCFParams, dcfResult, accCostsAtExit.landTransferTax, accCostsAtExit.agentCosts, accCostsAtExit.notaryCosts)
        
        const assetKpis : AssetKPIsDto = {
            totalArea: 0,
            grossRentalIncomeMonthly: dcfResult.assetCashFlow.totalGrossRentalIncome[0],
            grossRentalIncomeYear: dcfResult.assetCashFlow.totalGrossRentalIncome[0]*12,
            marketRentalIncomeYear: dcfResult.assetCashFlow.totalMArketRent[0]*12,
            potentialRentalIncomeYear: dcfResult.assetCashFlow.totalPotentialRent[0]*12,
            grossRentalIncomeSqm: 0,
            marketRentalIncomeSqm: 0,
            potentialRentalIncomeSqm: 0,
            marketRentalIncomeLetYear: 0,
            marketRentalIncomeLetSqm: 0,
            grossAssetValue: dcfResult.assetCashFlow.grossAssetValue,
            netAssetValue: dcfResult.assetCashFlow.netAssetValue,
            grossTerminalAssetValue: grossTerminalAssetValue,
            netTerminalAssetValue: netTerminalAssetValue,
            grossAssetValueSqm: 0,
            netAssetValueSqm: 0,
            landTransferPercentage: accCostsAtSale?.landTransferTax/100,
            notaryCostsPercentage: accCostsAtSale?.notaryCosts/100,
            agentCostsPercentage: accCostsAtSale?.agentCosts/100,
            landTransferValue: dcfResult.assetCashFlow.netAssetValue * accCostsAtSale?.landTransferTax/100,
            notaryCostsValue: dcfResult.assetCashFlow.netAssetValue * accCostsAtSale?.notaryCosts/100,
            agentCostsValue: dcfResult.assetCashFlow.netAssetValue * accCostsAtSale?.agentCosts/100,
            grossTerminalAssetValueSqm: 0,
            netTerminalAssetValueSqm: 0,
            landTransferExitPercentage: accCostsAtExit?.landTransferTax/100,
            notaryCostsExitPercentage: accCostsAtExit?.notaryCosts/100,
            agentCostsExitPercentage: accCostsAtExit?.agentCosts/100,
            landTransferExitValue: landTransferExitValue,
            notaryCostsExitValue: notaryCostsExitValue,
            agentCostsExitValue: agentCostsExitValue,
            walt: 0,
            rentalLevel: 0,
            let: 0,
            vacant: 0,
            vacancy: 0,
            occupancy: 0,
            netInitialYieldCurrentRent: 0,
            netInitialYieldMarketRent: 0,
            netInitialYieldPotentialRent: 0,
            grossInitialYieldCurrentRent: 0,
            grossInitialYieldMarketRent: 0,
            grossInitialYieldPotentialRent: 0,
            multipleInitialYieldCurrentRent: 0,
            multipleInitialYieldMarketRent: 0,
            multipleInitialYieldPotentialRent: 0,
            netInitialYieldTerminalCurrentRent: 0,
            netInitialYieldTerminalMarketRent: 0,
            netInitialYieldTerminalPotentialRent: 0,
            grossInitialYieldTerminalCurrentRent: 0,
            grossInitialYieldTerminalMarketRent: 0,
            grossInitialYieldTerminalPotentialRent: 0,
            multipleInitialYieldTerminalCurrentRent: 0,
            multipleInitialYieldTerminalMarketRent: 0,
            multipleInitialYieldTerminalPotentialRent: 0,
            financingLeveragedIRR: financingDetails?.leveragedIRR ? financingDetails?.leveragedIRR : null,
            financingCashOnCash: financingDetails?.cashOnCash ? financingDetails?.cashOnCash : null,
            financingWacc: financingDetails?.wacc ? financingDetails?.wacc : null,
            financingEquityMultiple: financingDetails?.equityMultiple ? financingDetails?.equityMultiple : null,
        };

        rentRolls.forEach((rentRollDto:RentRollDto) => addAssetKpiSummableValues(rentRollDto,assetKpis))
        rentRolls.forEach((rentRollDto:RentRollDto) => addAssetKpiWeightedValues(rentRollDto,assetKpis,analysisDate,dcfResult))
        calcYieldValues(assetKpis,dcfResult)
        
        //Relative values
        assetKpis.vacancy = assetKpis.vacant/assetKpis.totalArea
        assetKpis.occupancy = 1- assetKpis.vacancy
        assetKpis.grossAssetValueSqm = assetKpis.grossAssetValue/assetKpis.totalArea
        assetKpis.grossTerminalAssetValueSqm = assetKpis.grossTerminalAssetValue/assetKpis.totalArea
        assetKpis.netAssetValueSqm = assetKpis.netAssetValue/assetKpis.totalArea
        assetKpis.netTerminalAssetValueSqm = assetKpis.netTerminalAssetValue/assetKpis.totalArea
        assetKpis.grossRentalIncomeSqm = assetKpis.grossRentalIncomeYear/12/assetKpis.let
        assetKpis.marketRentalIncomeSqm = assetKpis.marketRentalIncomeYear/12/assetKpis.totalArea
        assetKpis.potentialRentalIncomeSqm = assetKpis.potentialRentalIncomeYear/12/assetKpis.totalArea
        assetKpis.marketRentalIncomeLetSqm = assetKpis.marketRentalIncomeLetYear/12/assetKpis.let
        assetKpis.rentalLevel = (assetKpis.grossRentalIncomeSqm/assetKpis.marketRentalIncomeLetSqm)-1
        
        return assetKpis
    }
    
    return null;
}

//--------------------------------------------- Asset Tenancy -----------------------------------------------//
//--------------------------------------------------------------------------------------------------------------------//

interface TenantIncomeDetail extends AreaAndIncomeDetail{
    totalUnits: number,
    totalArea: number,
    expiry: Moment,
    option1: Moment,
    option2: Moment,
    shareIncome: number
}
interface TenantIncomeDetailByTenantName extends TenantIncomeDetail{
    tenantName: string,
    rentRolls: [RentRollDto]
}

interface TenantIncomeDetails {
    totalValues: TenantIncomeDetail,
    top5totalValues: TenantIncomeDetail,
    rankedTenants: [TenantIncomeDetailByTenantName],
    totalTenants: number,
    totalTenantsExcludingUnits: number,
    shareIncomeTop5: number,
    shareIncomeTop3: number,
    shareIncomeProlongation: number,
    topTenantName: string,
}

function getTenantIncomeDetailZeroValue() : TenantIncomeDetail {
    return { ...getAreaIncomeDetailsZeroValue(),
        totalArea: 0,
        totalUnits: 0,
        expiry: null,
        option1: null,
        option2: null,
        shareIncome: 0,
    };
}

function addTenantsValues(rentRollDto: RentRollDto, tenantValues: TenantIncomeDetail, rentalType: string) {
    if(rentalType === "area")
    {
        tenantValues.totalArea += rentRollDto.rentalSpace
    }
    else if(rentalType === "unit")
    {
        tenantValues.totalUnits += rentRollDto.rentalSpace
    }
    
    if(rentRollDto.leaseEndDate){
        const newLeaseEnd = moment(rentRollDto.leaseEndDate)
        tenantValues.expiry = tenantValues.expiry != null && tenantValues.expiry.isAfter(newLeaseEnd) ? tenantValues.expiry : newLeaseEnd
    }

    if(rentRollDto.option1Date){
        const newOption1 = moment(rentRollDto.option1Date)
        tenantValues.option1 = tenantValues.option1 != null && tenantValues.option1.isAfter(newOption1) ? tenantValues.option1 : newOption1
    }

    if(rentRollDto.option2Date){
        const newOption2 = moment(rentRollDto.option2Date)
        tenantValues.option2 = tenantValues.option2 != null && tenantValues.option2.isAfter(newOption2) ? tenantValues.option2 : newOption2
    }
}

function addTenantsWeightedValues(rentRollDto: RentRollDto, tenantValues: TenantIncomeDetailByTenantName, totalValues : TenantIncomeDetail, analysisDate : Moment) {

    //WALT: Mietdauer ab AnalysisDate * currentRentGewichtet
    let remainingTerm: number = 0

    if(rentRollDto.leaseEndDate){
        const leaseEndDate: Moment = moment(rentRollDto.leaseEndDate);

        if(rentRollDto.rentRollStatusType?.key === "core-data.rentRollStatusTypes.let" && leaseEndDate.isSameOrAfter(analysisDate) ){
            remainingTerm = leaseEndDate.diff(analysisDate,"days")/365.25
        }
    }

    const currentRentWeight = rentRollDto.currentRentPerYear/tenantValues.grossRentalIncomeYear
    const walt = remainingTerm * currentRentWeight

    tenantValues.walt += walt
    totalValues.walt += walt *  tenantValues.grossRentalIncomeYear/totalValues.grossRentalIncomeYear
}

function sumTenantsTotalValues(tenantValues: TenantIncomeDetailByTenantName, totalValues : TenantIncomeDetail) {

    //summable values
    const paramNames = ["totalAreaUnits","totalArea","totalUnits","let","vacant","grossRentalIncomeYear","nonRecoverableCostsYear","netOperatingIncomeYear","marketRentalIncomeYear","marketRentalIncomeLetYear", "shareIncome"]
    paramNames.forEach( paramName => {
        totalValues[paramName] += tenantValues[paramName]
    })
    
    if(tenantValues.expiry){
        const newLeaseEnd = tenantValues.expiry
        totalValues.expiry = totalValues.expiry != null && totalValues.expiry.isAfter(newLeaseEnd) ? totalValues.expiry : newLeaseEnd
    }

    if(tenantValues.option1){
        const newOption1 = tenantValues.option1
        totalValues.option1 = totalValues.option1 != null && totalValues.option1.isAfter(newOption1) ? tenantValues.option1 : newOption1
    }

    if(tenantValues.option2){
        const newOption2 = tenantValues.option2
        totalValues.option2 = totalValues.option2 != null && totalValues.option2.isAfter(newOption2) ? tenantValues.option2 : newOption2
    }
}

function sumTenantsTotalRelativeWeightedValues(tenantValues: TenantIncomeDetailByTenantName, totalValues : TenantIncomeDetail) {
    totalValues.walt += tenantValues.walt *  tenantValues.grossRentalIncomeYear/totalValues.grossRentalIncomeYear    
}

function setTenantsTotalRelativeValues(totalValues : TenantIncomeDetail) {
    
    totalValues.vacancy = totalValues.vacant/totalValues.totalAreaUnits
    totalValues.occupancy = 1-totalValues.vacancy;
    totalValues.grossRentalIncomeSqm = totalValues.grossRentalIncomeYear/12/totalValues.let
    totalValues.nonRecoverableCostsPercentage = totalValues.nonRecoverableCostsYear/totalValues.marketRentalIncomeYear
    totalValues.netOperatingIncomeSqm = totalValues.netOperatingIncomeYear/12/totalValues.let
    totalValues.marketRentalIncomeSqm = totalValues.marketRentalIncomeYear/12/totalValues.totalAreaUnits
    totalValues.marketRentalIncomeLetSqm = totalValues.marketRentalIncomeLetYear/12/totalValues.let
    totalValues.rentalLevel = (totalValues.grossRentalIncomeSqm/totalValues.marketRentalIncomeLetSqm)-1
}

export const getTenantDetails = (rentRolls : RentRollDto[], analysisDate : Moment, useTypesCostsDefaults: {}) : TenantIncomeDetails => {
    const tenantIncomeDetails : TenantIncomeDetails = {
        totalValues: getTenantIncomeDetailZeroValue(),
        top5totalValues: getTenantIncomeDetailZeroValue(),
        rankedTenants: [],
        totalTenants: 0,
        totalTenantsExcludingUnits: 0,
        shareIncomeProlongation: 0,
        shareIncomeTop3: 0,
        shareIncomeTop5: 0,
    }
    
    const tenantsValuesByName : { [key: String]: TenantIncomeDetailByTenantName; } = {}

    rentRolls.forEach((rentRollDto:RentRollDto ) => {
        
        if("core-data.rentRollStatusTypes.let" !== rentRollDto.rentRollStatusType?.key)
            return;
        
        const tenantName = getRentRollTenantName(rentRollDto)
        const rentalType =  rentRollDto?.useType?.values?.unitType ? rentRollDto?.useType?.values?.unitType : "Unknown"
        const useTypeName = rentRollDto?.useType?.translations?.en ? rentRollDto?.useType?.translations?.en : "Unnamed"

        if(!tenantsValuesByName.hasOwnProperty(tenantName)){
            tenantsValuesByName[tenantName] = getTenantIncomeDetailZeroValue();
            tenantsValuesByName[tenantName].tenantName = tenantName
            tenantsValuesByName[tenantName].rentRolls = []
        }
        
        const tenantValues: TenantIncomeDetailByTenantName = tenantsValuesByName[tenantName] ;

        tenantValues.rentRolls.push(rentRollDto)
        addAreaIncomeSummableValues(rentRollDto, tenantValues, useTypeName, useTypesCostsDefaults);
        addAreaIncomeSummableValues(rentRollDto, tenantIncomeDetails.totalValues, useTypeName, useTypesCostsDefaults);
        addTenantsValues(rentRollDto,tenantValues,rentalType)
        addTenantsValues(rentRollDto,tenantIncomeDetails.totalValues,rentalType);
    })

    addAreaIncomeRelativeValues(tenantIncomeDetails.totalValues);

    Object.values(tenantsValuesByName).forEach(tenantValues => {
        addAreaIncomeRelativeValues(tenantValues)
        tenantValues.shareIncome = tenantValues.grossRentalIncomeYear/tenantIncomeDetails.totalValues.grossRentalIncomeYear
        tenantIncomeDetails.totalValues.shareIncome += tenantValues.grossRentalIncomeYear/tenantIncomeDetails.totalValues.grossRentalIncomeYear
    })

    rentRolls.forEach(rentRollDto => {
        if("core-data.rentRollStatusTypes.let" !== rentRollDto.rentRollStatusType?.key)
            return;
        
        const tenantName = getRentRollTenantName(rentRollDto)
        const tenantValues: TenantIncomeDetailByTenantName = tenantsValuesByName[tenantName]
        addTenantsWeightedValues(rentRollDto, tenantValues, tenantIncomeDetails.totalValues, analysisDate);
    })
    
    tenantIncomeDetails.rankedTenants = Object.values(tenantsValuesByName).sort((tenantA, tenantB) => tenantB.grossRentalIncomeYear-tenantA.grossRentalIncomeYear)

    tenantIncomeDetails.totalTenants = tenantIncomeDetails.rankedTenants.length
    tenantIncomeDetails.totalTenantsExcludingUnits = tenantIncomeDetails.rankedTenants.filter(tenantValues => tenantValues.totalArea > 0).length
    
    for (let i = 0; i < 3; i++) {
        if(tenantIncomeDetails.rankedTenants[i])
        {
            tenantIncomeDetails.shareIncomeTop3 += tenantIncomeDetails.rankedTenants[i].shareIncome
            tenantIncomeDetails.shareIncomeTop5 += tenantIncomeDetails.rankedTenants[i].shareIncome
            sumTenantsTotalValues(tenantIncomeDetails.rankedTenants[i], tenantIncomeDetails.top5totalValues)
        }
    }

    for (let i = 3; i < 5; i++) {
        if(tenantIncomeDetails.rankedTenants[i])
        {
            tenantIncomeDetails.shareIncomeTop5 += tenantIncomeDetails.rankedTenants[i].shareIncome
            sumTenantsTotalValues(tenantIncomeDetails.rankedTenants[i], tenantIncomeDetails.top5totalValues)
        }
    }

    for (let i = 0; i < 5; i++) {
        if(tenantIncomeDetails.rankedTenants[i])
        {
            sumTenantsTotalRelativeWeightedValues(tenantIncomeDetails.rankedTenants[i], tenantIncomeDetails.top5totalValues)
        }
    }

    setTenantsTotalRelativeValues(tenantIncomeDetails.top5totalValues)

    tenantIncomeDetails.rankedTenants.filter(tenantValues => tenantValues.option1 != null || tenantValues.option2 != null)
        .forEach(tenantValues => {
            tenantIncomeDetails.shareIncomeProlongation += tenantValues.shareIncome
        })
    
    tenantIncomeDetails.topTenantName = tenantIncomeDetails.rankedTenants[0] ? tenantIncomeDetails.rankedTenants[0].tenantName : "N/A";
    
    return tenantIncomeDetails;
}

//--------------------------------------------- Asset Financing KPIs -----------------------------------------------//
//--------------------------------------------------------------------------------------------------------------------//

interface FinancingDetail{
    loanAmount: number,
    loanType: string,
    cashOnCash: number,
    interestRate: number,
    irr: number,
    amortization: number,
    leveragedIRR: number,
    wacc: number,
    equityMultiple: number,
    cashOnCashYear1: number,
    cashProfit: number,
    ltv: number,
    ltc: number

}

export const getFinanceDetails = (analysisDate: String, dcfResult: DCFOutput, dcfParams: DCFParameters, assetTypesCapRatesDefaults: {}, assetType: string) : FinancingDetail => {
    
    if(!dcfParams.triggerFinancingCosts || !dcfResult) return null
    
    const discountRate = getDiscountRateOrDefault(dcfParams, assetTypesCapRatesDefaults, assetType)
    const equity = getFinancingEquity(dcfParams, dcfResult);
    const durationInMonths = getDurationInMonthsOrDefault(dcfParams)
    const ltv = getFinancingLTV(dcfParams,dcfResult);
    const interestRate = getFinancingInterestRate(dcfParams) //F66
    const financingCashflow = dcfResult.financingCashFlow.financingCF
    const runningCashOnCash = dcfResult.financingCashFlow.runningCashOnCash
    const leveragedCashFlow = dcfResult.financingCashFlow.leveragedCashFlow
    const validateAnalysisDate = getStartingDate(analysisDate)
    const validateAnalysisDateDayBefore = validateAnalysisDate.subtract(1, "days")
    const minusEquity = -1 *equity
    const xirrData: [XirrInput] = [
        { amount: minusEquity, date: validateAnalysisDateDayBefore.format("YYYY-MM-DD") }
    ]
    let nextMonthDate = validateAnalysisDate
    leveragedCashFlow.forEach(leveragedCashFlowValue => {
        xirrData.push({ amount: leveragedCashFlowValue, date: nextMonthDate.format("YYYY-MM-DD") })
        nextMonthDate = nextMonthDate.add(1,"months")
    })
    const calculatedXirr = xirr(xirrData);
    const leveragedIRR = convertRate(calculatedXirr.rate, 'year');
    
    const financeDetails: FinancingDetail = {
        ltv: ltv,
        ltc: 0,
        amortization: getFinancingAmortization(dcfParams),
        irr: discountRate,
        leveragedIRR: leveragedIRR,
        wacc: ltv*interestRate+(1-ltv)*leveragedIRR,
        cashOnCash: mean(runningCashOnCash),
        cashOnCashYear1: sum(financingCashflow.slice(0,12))/equity,
        cashProfit: 0,
        equityMultiple: sum(leveragedCashFlow)/equity,
        interestRate: interestRate,
        loanAmount: getFinancingLoan(dcfParams, dcfResult),
        loanType: dcfParams?.financingType ? dcfParams?.financingType.translations?.en : DCF_FINANCING_TYPE_I18N_LABEL
    }

    return financeDetails;
}