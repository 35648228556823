
let numberFormat = new Intl.NumberFormat();

export const getFormattedNumber = (number) => {
    return numberFormat.format(number)
}


export const getFormattedInt = (number) => {
    return numberFormat.format(parseInt(number))
}

export function getFormattedEnglishNumber(number) {
    if (typeof number === 'string') {
        number = number.replace(/,/g, ''); // Remove commas if any
    }

    const parsedNumber = parseFloat(number);

    if (isNaN(parsedNumber)) {
        return '';
    }

    const engFormat = Number(parsedNumber.toFixed(0)).toLocaleString('en-US');
    return engFormat;
}

export function getFormattedDecimalNumber(number) {
    // const decimalFormat = Number(number).toLocaleString('en-US', {
    //     minimumFractionDigits: 2,
    //     maximumFractionDigits: 2
    // });
    // return decimalFormat
    let parsedNumber = number;
    if (parsedNumber === null || parsedNumber === undefined) {
        parsedNumber = 0
    }
    return parseFloat(parsedNumber).toFixed(2);
}